import React from 'react'
import { Helmet } from 'react-helmet'

const rootUrl = 'https://www.maskofwellness.com';
// const siteAddress = new URL('https://www.maskofwellness.com')
export default ({ title, description, image }) => (
  <Helmet>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={`${rootUrl}${image}`} />
    {/*
      <meta property="og:url" />
      <meta property="og:type" />
      <meta property="fb:app_id" />
      <meta property="og:url" content="">
      <meta property="og:type" content="website" />
      https://ogp.me/
    */}
  </Helmet>
)
