import React from 'react'
import PropTypes from 'prop-types'

const secondaryPageHero = ({
  heroImg,
  iconImg,
  iconImgAlt,
  title,
  accentedTitle,
  subtitle,
}) => (
  <section className="hero gradientBg is-medium">
    <div
      className="secondary-page hero-body is-primary is-mow-border mow-hero"
      style={{ backgroundImage: `url(${heroImg})` }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-parent is-one-half secondary-hero-text">
            <div className="container">
              <h1 className="title is-4 has-text-white title-small">{title}</h1>
              <h1 className="title is-1 has-text-white is-spaced is-relative cursive">
                <img src={iconImg} className="hero-icon" alt={iconImgAlt} />
                {accentedTitle}
                <span className="period"></span>
              </h1>
              <h4 className="subtitle is-4 has-text-white">{subtitle}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

secondaryPageHero.propTypes = {
  heroImg: PropTypes.string.isRequired,
  iconImg: PropTypes.string.isRequired,
  iconImgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  accentedTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default secondaryPageHero
