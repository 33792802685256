import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-couple-umbrella.jpg'
import forBizSvg from '../images/icon-terms.svg'

const faqPage = () => (
  <Layout>
    <PageHelmet
      title="Accessibility Statement"
      description="Accessibility Statement"
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Web'}
      accentedTitle={'Accessibility'}
      subtitle={"Creating a good experience for everyone"}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-11 center-block">
            <div className="content">
              <h2>We Are Committed To Accessibility</h2>
              <p>As part of our commitment to a positive and empowering message, Mask of Wellness, LLC strives to maintain a website that is both accessible to all visitors and compliant with the Web Content Accessibility Guidelines (WCAG) put forth by the World Wide Web Consortium (W3C).
              </p>
              <p>We recognize that accessibility and usability are not always possible in every area of the website, or for those visitors using assistive technologies and devices. So Mask of Wellness conducts accessibility reviews of its website and remediates issues uncovered by these audits. Please be aware that our efforts are ongoing.
              </p>
              <h5>Enabling the Accessibility Menu</h5>
              <p>The accessibility menu can be enabled by clicking the accessibility menu icon that appears on the left side of every page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.
              </p>
              <h5>Have Feedback Or Need Help?</h5>
              <p>We welcome your feedback on the accessibility of Mask of Wellness. If you have specific questions or feedback about this site’s accessibility or need assistance using specific features, please contact us via email at <a href="mailto:support@maskofwellness.com">support@maskofwellness.com</a>. If you have found an inaccessible area on the site, please specify the web page or element and provide any other relevant information to help us locate the problem.
              </p>
              <p>In the event a page cannot be made accessible, we will work to make a text version of the content available. Please contact us via email at <a href="mailto:support@maskofwellness.com">support@maskofwellness.com</a> to request a specific electronic format. Please provide us with your contact information, the format you require, the web page address and the location of the content.
              </p>
              <p>As this is a new, volunteer-based initiative, we are currently working to setup a phone number. If you prefer to call, you may contact our web development team <a href="https://revivedesignstudios.com" target="_blank">Revive Design Studios</a> at <a href="tel:1-720-515-8498">720-515-8498</a> Monday through Friday from 9am-5pm MST.
              </p>
              <p>
                We welcome your questions about this accessibility statement and comments on how to improve our website’s accessibility.
              </p>
              <h5>Third Party Integrations & Websites</h5>
              <p>Mask of Wellness utilizes a number of third party tools and websites to support our website, including but not limited to Instagram, Facebook and Youtube. If these third party tools and websites are not accessible for all users, this is outside the control of Mask of Wellness. Below are links to the accessibility statements for some of these third party tools and websites:
              </p>
              <p>
                <a href="https://www.facebook.com/help/273947702950567" target="_blank">Facebook Accessibility Policy</a><br />
                <a href="https://support.google.com/youtube/answer/189278?hl=en" target="_blank">YouTube Accessibility Policy</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default faqPage
